.pointer {
  cursor: pointer;
}

.page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  height: 100vh;
  width: 100%;
  margin: auto;
  overflow: auto;
}

.vertical-section {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: stretch;
}

.horizontal-section {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: stretch;
}

.page-content {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: auto;
  width: 100%;
  overflow: hidden;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  border-radius: $rounded-pill;
}

::-webkit-scrollbar-thumb {
  background-color: $gray-400;
  -webkit-box-shadow: inset 0 0 5px $gray-500;
  box-shadow: inset 0 0 5px $gray-500;
  border-radius: $rounded-pill;

  &:hover {
    background: $gray-500;
  }
}

.footer-fixed-bottom {
  z-index: 12 !important;
}

.cycle-align {
  width: auto;
  height: auto;
  margin: auto;
}

.inner-cycle {
  padding: $spacer * 3;
  position: relative;
}

.mensuration-icon,
.autumn-icon,
.sun-icon,
.follicular-icon,
.body-status-tick-icon,
.body-status3-icon,
.body-status2-icon,
.body-status1-icon,
.body-status-min-icon,
.body-dotted-status-icon,
.body-status-plus-icon {
  position: absolute;
  width: 36px;
  height: 36px;
  line-height: 36px;
  box-shadow: $box-shadow;
  background-color: $white;
  border-radius: $rounded-pill;
  z-index: 1;
  &:hover {
    opacity: 1;
  }
}

.chart-circle {
  position: relative;
  &.MENSTRUATION {
    image {
      transform: translateX(-2px);
    }
  }
  &.FOLLICULAR {
    image {
      transform: translateX(-25px);
    }
  }
  &.OVULATION {
    image {
      transform: translateY(-6px);
    }
  }
  &.LUTEAL {
    image {
      transform: translateX(-10px);
    }
  }
}

.mensuration-icon {
  top: 44px;
  right: 30px;
}

.autumn-icon {
  top: 45px;
  left: 28px;
}

.sun-icon {
  bottom: 5px;
  left: 24px;
}

.follicular-icon {
  bottom: 20px;
  right: 15px;
}

.body-status-tick-icon {
  top: 0px;
  right: 55px;
}
.body-status3-icon {
  top: 65px;
  right: 5px;
}
.body-status2-icon {
  top: 135px;
  right: 3px;
}
.body-status1-icon {
  top: 205px;
  right: 48px;
}
.body-status-min-icon {
  top: 205px;
  left: 42px;
}
.body-dotted-status-icon {
  top: 125px;
  left: 0px;
}
.body-status-plus-icon {
  top: 30px;
  left: 25px;
}

.overflow-scroll {
  overflow: auto;
  max-height: 350px;
}

.plusIcon {
  font-size: $font-size-lg;
}

// updated css

.slick-dots {
  bottom: 3px;
  text-align: left;
  width: 97%;
  margin: 0 auto;
  left: $spacer * 1.5;
  right: 0;
}

.slick-initialized .slick-slide > div {
  margin: 0 10px;
}

.trackCyclePicker {
  .rdrDateDisplay {
    display: none;
  }
  span.rdrStartEdge.rdrEndEdge {
    background: $danger;
  }
}

.rdrMonth {
  margin: 0 auto;
}

.cycleDesc {
  .font-size-base {
    font-size: $font-size-xsmall;
  }
  .font-size-msmall {
    font-size: $font-size-small !important;
  }
}

.cycleChartWrapper {
  width: 71%;
}

.ribbon-title--b {
  background-color: $white;
  color: $black;
  display: flex;
  filter: drop-shadow(0 1px 2px rgba($black, 0.5));
  font-size: $font-size-msmall;
  line-height: 1.02;
  margin: 0 auto 1rem;
  max-width: 60%;
  padding: 0;
  position: relative;
  text-align: center;
  transition: all 300ms;
  justify-content: center;
  min-height: 30px;
  align-items: center;
  font-weight: $font-weight-bold;
  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    border: 15px solid $white;
    z-index: -1;
  }
  &::before {
    left: -30px;
    border-left: 20px solid transparent;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  &::after {
    right: -30px;
    border-right: 20px solid transparent;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.no-shadow {
  box-shadow: none !important;
}

.css-1tzy9xj-Slider {
  width: 96%;
  padding: 0 2%;
}

.corePageContent {
  div[data-block="true"],
  p {
    line-height: 24px;
  }
  ol,
  ul {
    margin-block-start: $spacer * 1.5;
    padding-inline-start: $spacer * 1.5;
    li {
      padding-bottom: $spacer;
      line-height: 24px;
      text-align: justify;
    }
  }
}

.getStartedComponent {
  width: 230px;
  margin: 0 auto;
}

.authUserComponent,
.corePagesComponent,
.trackCycleComponent,
.editPeriodDateComponent {
  width: 288px;
  margin: 0 auto;
}

.editPeriodDateComponent {
  .rdrCalendarWrapper {
    width: 100%;
  }
}

.bodyComponent,
.homePagesComponent,
.headerComponent,
.footerComponent,
.fixed-bottom,
.appInnerWrapper {
  width: 100%;
  margin: 0 auto;
}

.cycleAlignDescription {
  line-height: 22px;
}

.homeQuickTip {
  font-size: 10px;
}

.homeHealthTipsCol {
  width: 55%;
  float: left;
}

.referCardAlign {
  line-height: 78px;
}

.scoreIndicatorChart {
  font-size: $h2-font-size;
  position: relative;
  top: $spacer;
  height: 90px;
  svg,
  text {
    fill: $black;
    transform: translateY(-22px);
  }
  &:after {
    content: "Days";
    font-size: 10px;
    position: relative;
    top: -115px;
  }
}

.communityPage {
  .stackable,
  .main-wrapper {
    width: 290px !important;
  }
}
.healthcardWrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 8px;
}
.healthcardWrapper .innerHealthCard {
  width: 90%;
}
.healthcardWrapper .innerHealthCard .heading-sm{
  font: normal normal 600 18px/26px Poppins;
}

.faqSearchFilter.signup-home-form {
  height: 51px;
}
.accordion-header {
  font: normal normal 600 13px/20px Poppins;
  color: #25282B;
  display: flex;
  justify-content: center;
  align-items: center;
}
.accordion-body {
  font: normal normal normal 12px/18px Poppins;
  color: #25282B;
}
.card-header.faqHeader {
  background: #B5FFE9 !important;
  padding: 0 1rem !important;
  min-height: 81px;
  justify-content: flex-start;
  display: flex;
  align-items: center;
  outline: none;
}
.card-header.faqHeader div {
  outline: none;
  padding-right: 0.5rem;
}
.card-header.faqHeader > div[aria-expanded='false'] small, 
.card-header.faqHeader > div[aria-expanded='true'] small {
  width: 13px;
  height: 3px;
  background: #25282b;
  position: absolute;
  right: 14px;
  top: 45%;
  margin: 0 0 -15px;
}
.card-header.faqHeader {
  position: relative;
}
.card-header.faqHeader > div[aria-expanded='false'] small:after {
  content: "";
  background: #25282b;
  position: absolute;
  width: 13px;
  height: 3px;
  top: 0;
  right: 0px;
  transform: rotate(90deg);
}
.isDisibled {
  opacity: 0.4;
  pointer-events: none;
}
.underline-on-hover:hover, .underline-on-hover:focus {
  text-decoration: underline !important;
}
.articleBody {
  font: normal normal normal 13px/20px Poppins;
}
.articleBody h1, .articleBody h2, .articleBody h3, .articleBody h4, .articleBody h5, .articleBody h6 {
  font: normal normal 600 18px/24px Poppins;
  margin-bottom: 1rem;
  margin-top: 2rem;
}
.badge-sup-top-left-img.card-list-article {
  position: relative;
  top: auto;
  left: auto;
}
.badge-sup-top-left .badge a, .badge-sup-top-left-img .badge a {
  color: #dc3545 !important
}
.blogGrid {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: flex-end;
}

.blogGrid .blogGridItem {
  width: 46%;
  height: 188px;
  margin-bottom: 4%;
}

.blogGrid .blogGridItem img {
  width: 100%;
  height: 117px;
}

.blogGrid .blogGridItem:nth-child(odd) {
  margin-right: 4%;
}
.blogTag {
  padding: 0.2rem 0.5rem;
  margin: 0.2rem;
}
.card a {
  color: #25282B;
}
.blogGridImg {
  background-size: cover !important;
  min-height: 120px;
}
.blogInnerCover {
  min-height: 300px;
  background-size: cover !important;
  width: 100%;
}
.blogInnerCover img {
  width: 100%;
}
.backgroundCover {
  background-size: cover !important;
}
.blogTag {
  padding: 2px 8px !important;
}
.swiper-pagination.swiper-pagination-fraction {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  justify-content: center;
  text-align: center;
}
//Notification CSS

.notification-ui_dd {
  width: 97%;
  display: flex;
  flex-direction: column;
}

.notification-ui_dd .notification-ui_dd-header {
  background: #fff;
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  box-shadow: 0px 30px 60px #97a6b529;
  border-bottom: 1px solid #ddd;
}

.notification-ui_dd .notification-ui_dd-header h3 {
  margin: 0;
  font-size: 17px;
}

.notification-ui_dd-content {
  margin-top: 1rem;
  position: relative;
}

.notification-ui_dd-content .notification-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 20px 0;
  margin: 0 25px;
  border-bottom: 1px solid #ddd;
  position: relative;
}

.notification-list .notification-list_img img {
  height: 48px;
  width: 48px;
  border-radius: 50px;
  margin-right: 20px;
}

.sidenav.notification {align-items: flex-start;overflow: hidden !important;}

.notification-list--unread:before {
  content: "";
  position: absolute;
  top: 0;
  left: -25px;
  height: calc(100% + 1px);
  border-left: 2px solid #29B6F6;
}
.articleBody img {
  width: 100%;
}
.articleBody iframe {
  width: 100% !important;
  height: 300px !important;
}
.Toastify__toast {
  text-align: center;
  margin-bottom: 0 !important;
  font-size: 14px;
  position: fixed !important;
  width: 100%;
}

.trackCycle .rmdp-day-hidden span {
  border: none !important;
  opacity: 0;
}
.trackCycle .rmdp-day.rmdp-today span {
  background: #86E3CE;
  color: #1D2433;
}
.trackCycle .rmdp-day.rmdp-selected span:not(.highlight) {
  background: #FC887B 0% 0% no-repeat padding-box;
  text-align: center;
  letter-spacing: 0px;
  color: #1D2433;
  opacity: 1;
  box-shadow: none;
}
.trackCycle.rmdp-wrapper {
  width: 100%;
}
.trackCycle .rmdp-week:first-child {
  background: #F6F5FB;
  border-radius: 15px;
}
.trackCycle .rmdp-week:first-child .rmdp-week-day {
  color: #1D2433;
  font: normal normal 600 12px/20px Poppins;
}
.trackCycle .rmdp-week div span {
  font-size: 12px !important;
}
.trackCycle .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
  background: transparent;
  color: #1D2433;
}
.trackCycle .rmdp-arrow {
  border-color: #000;
}
.trackCycle .rmdp-arrow-container:hover {background: none;color: #000;box-shadow: none;}
.trackCycle .rmdp-arrow-container:hover .rmdp-arrow {border-color: #000;}
.trackCycle.rmdp-wrapper.disabled .rmdp-header+div:after {
  content: "";
  background: transparent;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  cursor: not-allowed;
}
.trackCycle .rmdp-calendar {
  padding: 0;
  margin: 0 !important;
  width: 100%;
}
.trackCycle .rmdp-week {
  justify-content: space-around;
  margin-bottom: 2r;
  margin-bottom: 0.7rem;
}
.trackCycle.editTrackCycle .rmdp-day.rmdp-selected span {
  background: #FC887B !important;
}
.datepicker .datepicker-col-1 {
  margin: 0 !important;
}
.datepicker .datepicker-content {
  padding: .5em 0 !important;
}
.datepicker .datepicker-scroll>li {
  font-size: 15px !important;
}

.rounded-circle-white-bg:hover, 
.rounded-circle-white-bg.active {
  border: 2px solid rgba($green, 0.9) !important;
}

.foodTipsWrapper {
  min-height: 250px;
}
.foodTipSlider button {
  position: absolute;
  top: 10px;
  z-index: 1;
  width: auto;
  line-height: normal;
  height: auto;
}
.foodTipSlider button.slick-arrow.slick-prev {
  left: 2%;
}
.foodTipSlider button.slick-arrow.slick-next {
  right: 2%;
}
.foodTipSlider button.slick-arrow.slick-next:before {font-family: 'Font Awesome 5 Pro';font-size: 1.5rem;color: #000;}
.foodTipSlider button.slick-arrow.slick-prev:before {
  font-family: 'Font Awesome 5 Pro';
  font-size: 1.5rem;
  color: #000;
}

.foodTipsWrapper .heading {
  text-align: center;
  font: normal normal bold 16px/20px Poppins;
  letter-spacing: 0px;
  color: #1D2433;
  opacity: 1;
}

.foodTipsWrapper .content {
  text-align: center;
  font: normal normal 300 16px/29px Poppins;
  letter-spacing: 0px;
  color: #1D2433;
  display: flex;
  height: 230px;
  justify-content: center;
  align-items: center;
}

.fastingTooltip {
  background: #fff;
  padding: 1rem;
  box-shadow: 0 6px 12px 0px rgba(0,0,0,0.5);
  border-radius: 9px;
}
.fastingTooltip h6 {
  font-size: 12px;
  color: #009688;
}
.fastingTooltip ul {
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: left;
}
.imgFullWidth {
  display: block !important;
}
.referFriendWrapper {
  max-width: 300px;
  margin: 0 auto;
}
.footerNav .activeFooterRoute{
  color: #000;
}
.footerNav .activeFooterRoute .foryou {
  background-position: -3px -32px !important;
}
.footerNav .activeFooterRoute .weightloss {
  background-position: -34px -33px !important;
}
.footerNav .activeFooterRoute .communitiy {
  background-position: -63px -33px !important;
}
.footerNav .activeFooterRoute .fasting {
  background-position: -93px -33px !important;
}
.footerNav .activeFooterRoute .blog {
  background-position: -122px -33px !important;
}
.showPassword:focus,
.showPassword {
  box-shadow: 0px 15px 30px #e9ecef !important;
  border-radius: 50rem !important;
  background: #ffffff;
  outline: none;
  margin-left: 0.5rem;
  border: none;
  width: 50px;
  padding: 0;
}
.sisterhoodWrapper {
  height: calc(100vh - 130px);
  width: 100%;
}